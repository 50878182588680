// @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Overpass&display=swap");

* {
  font-family: "Overpass", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: none;
}

.capital {
  text-transform: capitalize;
}

#cx-main {
  ::-webkit-scrollbar {
    display: none;
  }

  .innerImages {
    ::-webkit-scrollbar {
      display: block !important;
      width: 15px !important;
    }
  }

  position: relative;
  overflow-x: visible;
  margin-left: 250px;
  transition: all 0.5s;
  padding: 80px 10px 20px 20px;
  height: 100vh;

  background-color: var(--backColorMainLightMode);
}

.cx-active {
  margin-left: 80px !important;
}

// Time picker of antd customize styles
.ant-space {
  display: block !important;
}

.ant-picker .ant-picker-input > input {
  color: #8f4300 !important;
  font-size: 14px !important;

  &::placeholder {
    color: rgba(156, 73, 0, 0.5) !important;
  }
}

.ant-picker-focused {
  box-shadow: none !important;
}

.ant-btn-primary {
  background-color: #8f4300 !important;
  color: #fff !important;
}

.ant-picker .ant-picker-suffix {
  color: #9c4900 !important;
}

.custom-tooltip {
  --bs-tooltip-bg: var(--bd-violet-bg);
  --bs-tooltip-color: var(--bs-white);
}
.custom-select .ant-select-selector .ant-select-selection-item {
  color: rgba(156, 73, 0);
}
// ||===========================> BUTTONS  <==============================||

.cx-btn-1 {
  cursor: pointer;
  background-color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #9c4900 !important;
  border-radius: 20px;
  padding: 10px 20px 7px;
  height: 40px;
  min-width: 100px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 1px solid #9c4900;
  margin: 0 0.2rem;

  &:hover {
  }
}

.cx-btn-2 {
  // cursor: pointer;
  background-color: #9c4900;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff /* !important */;
  border-radius: 20px;
  padding: 10px 20px 7px;
  height: 40px;
  min-width: 100px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 0 0 0 0.2rem;

  &:hover {
  }
}

.cx-btn-load {
  color: #61b33b !important;
}
.cx-btn-2-add-merchant {
  background-color: #9c4900;
  font-weight: 500;
  font-size: 12px;
  color: #FFFFFA;
  border-radius: 8px;
  padding: 5px;
  height: 33px;
  min-width: 100px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 0 0 0 0.2rem;
}

.cx-btn-load {
  color: #61b33b !important;
}

.cx-btn-3 {
  cursor: pointer;
  background-color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #9c4900 !important;
  border-radius: 10px;
  padding: 7px 20px;
  height: 40px;
  min-width: 140px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 1px solid #f6efe9;
  margin: 0 0.2rem;

  &:hover {
  }
}

.btn-active-ann {
  background-color: #9c4900;
  color: #fff !important;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border-radius: 10px;
  padding: 7px 20px;
  height: 40px;
  min-width: 140px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 1px solid #f6efe9;
  margin: 0 0.2rem;

  &:hover {
  }
}

// ||===========================> INPUTS <==============================||

.cx-input-1 {
  font-size: 14px;
  // line-height: 2.5 !important;
  height: 38px;
  border: 0.956px solid #f6efe9 !important;
  border-radius: 10px !important;
  background-color: #fff;
  color: #9c490080 !important;

  &::placeholder {
    color: rgba(156, 73, 0, 0.5) !important;
    font-size: 12px !important;
  }

  &:focus-visible {
    outline: none;
  }
}

.cx-input-2 {
  font-size: 14px;
  line-height: 2.5 !important;
  height: 38px;
  border: 0.956px solid #53b7e8 !important;
  border-radius: 5px !important;
  background-color: #ffffff !important;
  color: #000000 !important;

  &::placeholder {
    color: gray !important;
    font-size: 14px;
  }
}

.form-switch .form-check-input {
  background-image: url(../images/ic_brown_circle.svg) !important;
  cursor: pointer;
  height: 14px;
  width: 28px !important;
}

// Green radio customize
#customRadios {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .greenFlex {
    display: flex;
    flex-direction: row-reverse;
  }

  .form-check-input {
    height: 13px;
    width: 13px;
  }

  .form-check-input:checked[type="radio"] {
    background-color: #04b54c !important;
    background-image: url(../images/right.svg) !important;
    background-size: 11px !important;
  }

  .custLabel {
    color: #703c19 !important;
    margin-right: 2rem;
  }
}

#switchMain {
  .form-check-input {
    border: 1px solid #8f4300 !important;
    margin-right: 0.5rem;
    cursor: pointer;
    background-image: url(../images/ic_brown_circle.svg) !important;

    &:checked {
      border: none !important;
      background-color: #8f4300 !important;
    }

    &:focus {
      box-shadow: none !important;
    }
  }
}

.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  // filter: rgba(143, 67, 0, 0.5) !important;
  color: red;
}
.widthAdjuster {
  width: 180px !important;
}
.form-select {
  border: 0.956px solid #f6efe9 !important;
  color: rgba(143, 67, 0) !important;
  border-radius: 10px !important;
  font-size: 14px !important;
  height: 40px;
  cursor: pointer;
  background-image: url(../images/selectDD.svg) !important;
  background-size: 11px 13px !important;

  &:invalid {
    color: rgba(143, 67, 0, 0.5) !important;
  }

  option {
    background: #fff !important;
    color: rgba(143, 67, 0, 0.5) !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.input_style{
  text-align: center;
  display: block;
  width: 95%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #ffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: .375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  border: 0.956px solid #f6efe9 !important;
  color: rgba(143, 67, 0) !important;
  font-size: 14px;
  height: 33px;
  border-radius: 10px !important;

  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  &::placeholder {
    color: rgba(143, 67, 0, 0.5) !important;
  }

}

// form select
.form-control,
.form-textarea {
  border: 0.956px solid #f6efe9 !important;
  color: rgba(143, 67, 0) !important;
  font-size: 14px;
  height: 40px;
  border-radius: 10px !important;

  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  &::placeholder {
    color: rgba(143, 67, 0, 0.5) !important;
  }
}

// Green checkbox
#customizeTable,
.greenCheck {
  .form-check-input {
    cursor: pointer;
    border-radius: 50% !important;
    height: 12px;
    width: 12px;
    margin-right: 0.5rem;
    margin-top: 0.35em !important;

    &:checked {
      background-color: #04b54c !important;
      border: #04b54c;
    }
  }
}

.innerFlexy {
  display: flex;
  flex-direction: row-reverse;

  label {
    cursor: pointer;
    margin-right: 0.5rem !important;
  }

  .form-check-input {
    height: 0.9em !important;
    width: 0.9em !important;
  }
}

.cx-input-radio {
  font-size: 14px;
  line-height: 2.5 !important;
  border-radius: 5px !important;
  background-color: #ffffff !important;
  color: #000000 !important;

  &::placeholder {
    color: gray !important;
    font-size: 14px;
  }
}

.cx-input-check {
  font-size: 14px;
  line-height: 2.5 !important;
  height: 38px;
  border-radius: 5px !important;
  background-color: #ffffff !important;
  color: #000000 !important;

  &::placeholder {
    color: gray !important;
    font-size: 14px;
  }
}

// Checkbox sliders
.form-check-input {
  border: 1px solid rgba(156, 73, 0, 0.5) !important;

  &:checked {
    border: none !important;
    background-color: #8f4300 !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

// ||===========================> HEADINGS <==============================||
.cx-heading-1 {
  font-weight: 600;
  font-size: 18px;
  color: rgba(17, 17, 17, 0.8);
}

.cx-heading-2 {
  font-weight: 600;
  font-size: 16px;
  color: rgba(17, 17, 17, 0.8);
}

.cx-heading-3 {
  font-weight: 600;
  font-size: 14px;
  color: rgba(209, 38, 38, 0.8);
}

.cx-label-1 {
  font-weight: 400;
  font-size: 14px;
  color: rgba(17, 17, 17, 0.6);
}

.cx-label-2 {
  font-weight: 400;
  font-size: 14px;
  color: rgba(17, 17, 17, 0.6);
}

.cx-key {
  font-weight: 400;
  font-size: 12px;
  color: rgba(17, 17, 17, 0.5);
}

.cx-value {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #111111;
}

.background_main_wrapper {
  background-color: var(--backColorMainLightMode);
}

.custom-wrapper-height {
  height: calc(100% - 135px);
}

// ||===========================> SCROLLS <==============================||

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-track-piece {
  height: 50%;
}

::-webkit-scrollbar {
  // display: none;
  height: 1px;
  width: 1px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #8f4300;
}

// ||===========================> COLORS <==============================||
body {
  --backColorMainLightMode: #f6efe9;
  --backColorMainDarksecond: #f6efe9;
  --mainHeadingDarkColor: #8f4300;
  --headingLightColor: #8f430080;
  --menuDark: rgba(67, 40, 24, 0.8);
  --menuLight: rgba(156, 73, 0, 0.9);
}

p {
  margin: 0;
  padding: 0;
}

.activeColorBrown {
  color: var(--mainHeadingDarkColor) !important;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.c-pointer {
  cursor: pointer;
}

.reg-color {
  color: #8f4300;
  font-size: 14px;
  position: absolute;
  padding: 10px;
top: 94.5%;
width: 100%;
background-color: #fff;  
}

.reg-coloranaunce {
  color: #8f4300;
  font-size: 14px;
  // position: absolute;
  top: 89%;
  margin-top: 55px;
  position: absolute;
  // top: 89%;
}

.reg-color-dispatch {
  color: #8f4300;
  font-size: 14px;
  position: absolute;
  // top: 91%;
  bottom: 20px;
}

.reg-colorDriver {
  color: #8f4300;
  font-size: 14px;
  position: absolute;
  top: 95%;
}

.reg-colorFeatureSet {
  color: #8f4300;
  font-size: 14px;
  position: absolute;
  top: 95%;
}

.reg-colorMerchant {
  color: #8f4300;
  font-size: 14px;
  position: absolute;
  top: 95%;
}

.reg-colortrips {
  color: #8f4300;
  font-size: 14px;
  position: absolute;
  top: 96%;
  padding: 5px;
  width: 100%;
  background-color: #fff;
}

a {
  text-decoration: none !important;
  color: rgba(156, 73, 0) !important;
  // color: var(--menuLight) !important;
}

// .modal-backdrop {
//   background-color: #421f00 !important;
// }

.red-star {
  color: #ff0000;
  font-weight: 500;
  font-size: 14px;
  margin-left: 5px;
}
.error-class-t{
  width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
}

#cx-wrapper {
  .innerImages {
    ::-webkit-scrollbar {
      display: block !important;
      width: 15px !important;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }

  // border-radius: 0 0 20px 20px;
  max-height: calc(100vh - 90px);
  overflow-y: auto;
  // padding-right: 4px;
  overflow-x: hidden;
}

.btn-close {
  background: transparent url(../images/modal_cross.svg) center/1em auto
    no-repeat !important;
}

.switch-select {
  border: 1.5px solid #9c4900 !important;
  margin-right: 0.5rem;
  cursor: pointer;
  background-image: url(../images/ic_brown_circle.svg) !important;
}

.cx-relative {
  position: relative;
}

.gm-control-active {
  height: 30px !important;
  width: 30px !important;
}

.gmnoprint {
  display: none !important;
  height: 60px !important;

  div {
    width: 30px !important;
  }
}

.gm-control-active.gm-fullscreen-control {
  margin: 5px !important;
  height: 25px !important;
  width: 25px !important;
  top: 92% !important;
  right: 0 !important;

  img {
    width: 13px !important;
    height: auto !important;
  }
}

.map-main {
}

textarea {
  // height: auto !important;
  ////new css added by me
  height: 35px;
  border-radius: 10px;
  left: 1.6rem;
  margin: -5px;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none !important;
}

.form_input_main {
  margin-bottom: 10px;
}

.scroll_none {
  ::-webkit-scrollbar {
    display: none !important;
  }
}

// .datepicker-main {
//   // position: relative;

//   // img {
//   //   position: absolute;
//   //   top: 12px;
//   //   right: 12px;
//   // }
// }

.tab-content > .active {
  transition: all 1s ease-in-out;
}

.none {
  display: none;
}

.form-check-input[type="radio"] {
  cursor: pointer;
}

.react-time-picker {
  .react-time-picker__inputGroup__input {
    &:focus-visible {
      outline: none !important;
    }

    color: rgba(156, 73, 0, 1);
  }

  .react-time-picker__button svg {
    stroke: rgba(156, 73, 0, 1);
  }

  width: 100%;

  .react-time-picker__wrapper {
    border: none;
  }
}

// .form-control[type="file"]:not(:disabled):not([readonly]) {
//   color: rgba(156, 73, 0, 0.5) !important;
//   font-size: 14px;
// }

:root {
  --primary-color: #185ee0;
  --secondary-color: #e6eef9;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

.leaflet-container {
  z-index: 0;
  overflow: auto;
  height: 100%;
  filter: brightness(100%);
}
.leaflet-control-attribution {
  font-size: 18px;
}
/* Make map labels more visible */
.leaflet-pane-labels {
  z-index: 650; /* Adjust the z-index to control the order of labels */
}

.leaflet-label {
  font-size: 18px; /* Adjust the font size for labels */
  color: #ffffff; /* Set label text color */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* Add a text shadow for better visibility */
}

.btn-map-change {
  span {
    color: var(--mainHeadingDarkColor);
  }

  padding: 7px 15px;
  border-radius: 20px;
  margin-right: 10px;
  border: none;
  background-color: var(--backColorMainLightMode);
}

// .leaflet-touch .leaflet-bar a:first-child{
//   margin-bottom: 10px;
//   border-radius: 10px;
//   color: #fff !important;

// }

// .leaflet-bar a{
//   background-color: #9c4900 !important;
//   color: #fff !important;
// }

.leaflet-draw-draw-rectangle {
  background-image: url(../images/whiteBox.svg) !important;
  border-radius: 7px !important;
  background-position: 50% 50% !important;
}

.leaflet-draw-draw-polygon {
  background-image: url(../images/whiteTrash.svg) !important;
  background-position: 50% 50% !important;
  border-radius: 7px !important;
  margin-right: 5px;
}

.leaflet-draw-edit-remove {
  background-image: url(../images/whiteBin.svg) !important;
  background-position: 50% 50% !important;
  border-radius: 7px !important;
}

.leaflet-draw-edit-edit {
  background-image: url(../images/edit_map_ic.svg) !important;
  background-position: 50% 50% !important;
  border-radius: 7px !important;
  margin-right: 5px;
}

.leaflet-draw-draw-circle {
  background-image: url(../images/circle_map_ic.svg) !important;
  background-position: 50% 50% !important;
  border-radius: 7px !important;
  margin: 0 5px;
}

.leaflet-control-zoom-out,
.leaflet-control-zoom-in {
  border-radius: 7px !important;
}

.leaflet-draw-toolbar-top {
  display: flex;
}

.leaflet-draw-toolbar.leaflet-bar {
  display: flex;
}

.leaflet-draw.leaflet-control {
  display: flex;
}

.leaflet-draw-toolbar {
  margin-top: 0 !important;
}

.leaflet-bar a {
  background-color: #9c4900 !important;
}

.leaflet-draw-toolbar a {
  background-size: 300px 20px !important;
}

.leaflet-touch .leaflet-bar a {
  color: #fff !important;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none !important;
}

.leaflet-draw-actions a {
  background-color: #f6efe9 !important;
  color: #8f4300 !important;
}

// import whiteTrash from "../../assets/images/whiteTrash.svg";
// import whiteHand from "../../assets/images/whiteHand.svg";
// import whiteBox from "../../assets/images/whiteBox.svg";
// import whiteBin from "../../assets/images/whiteBin.svg";
.loader-wrapper {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
}

.p-none {
  .dropdown-menu {
    display: none !important;
  }

  cursor: not-allowed !important;

  .dropdown {
    cursor: not-allowed !important;

    button {
      cursor: not-allowed !important;
    }
  }
}

.leaflet-control-zoom-fullscreen.fullscreen-icon {
  border-radius: 7px !important;
  color: #fff;
}

.leaflet-retina .fullscreen-icon {
  background-image: url(../images/ic_zoom.svg) !important;
  background-size: 20px 20px !important;
}

.fullscreen-icon {
  background-image: url(../images/ic_zoom.svg) !important;
  background-size: 20px 20px !important;
}

.count-record {
  padding: 2px 5px 0 5px;
  display: inline-block;
  position: absolute;
  border-radius: 10px;
  margin-left: 5px;
  font-size: 12px;
  // background-color: red;
  color: #fff;
  top: 0;
}

.react-datepicker__triangle::after {
  right: 20px;
}

.react-datepicker__triangle {
  left: -19px !important;
  top: 1px !important;
}

.react-datepicker {
  border: none !important;
  background-color: transparent;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #f8dbc1 !important;
}

.react-datepicker__day--outside-month {
  opacity: 0.3;

  &:hover {
    transform: scale(0);
  }
}

.react-datepicker__month-container {
  background-color: rgba(143, 67, 0, 0.156) !important;
  border-radius: 10px;

  .react-datepicker__header {
    background-color: #f8dbc1 !important;
    border: none !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px;

    .top_section {
      display: flex;
      justify-content: space-between;
      padding: 7px 7px;

      button {
        background-color: transparent;
        border: none;
        color: #8f4300;
        font-weight: 900;
      }

      select {
        background-color: transparent;
        border: 1px solid rgba(156, 73, 0, 0.8) !important;
        border-radius: 10px;
        padding: 2px 7px;
        color: #8f4300;
        max-height: 200px !important;

        &::after {
          height: 200px;
        }

        &:focus-visible {
          outline: none !important;
        }
      }
    }

    .react-datepicker__day-names {
      .react-datepicker__day-name {
        color: #8f4300;
        background-color: transparent;
      }
    }
  }

  .react-datepicker__month {
    .react-datepicker__week {
      .react-datepicker__day:hover,
      .react-datepicker__month-text:hover,
      .react-datepicker__quarter-text:hover,
      .react-datepicker__year-text:hover {
        background-color: transparent;
        font-weight: 900;
        transform: scale(1.1);
        transition: 0.2s;
      }

      .react-datepicker__day--keyboard-selected,
      .react-datepicker__month-text--keyboard-selected,
      .react-datepicker__quarter-text--keyboard-selected,
      .react-datepicker__year-text--keyboard-selected {
        background-color: transparent;
      }

      .react-datepicker__day--selected,
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--selected,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--selected,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--selected,
      .react-datepicker__year-text--in-selecting-range,
      .react-datepicker__year-text--in-range {
        background-color: transparent;
        border: 1px solid rgba(143, 67, 0, 1);
      }

      .react-datepicker__day--selected:hover,
      .react-datepicker__day--in-selecting-range:hover,
      .react-datepicker__day--in-range:hover,
      .react-datepicker__month-text--selected:hover,
      .react-datepicker__month-text--in-selecting-range:hover,
      .react-datepicker__month-text--in-range:hover,
      .react-datepicker__quarter-text--selected:hover,
      .react-datepicker__quarter-text--in-selecting-range:hover,
      .react-datepicker__quarter-text--in-range:hover,
      .react-datepicker__year-text--selected:hover,
      .react-datepicker__year-text--in-selecting-range:hover,
      .react-datepicker__year-text--in-range:hover {
        background-color: transparent !important;
      }

      .react-datepicker__day {
        color: #8f4300;
      }
    }
  }
}

.of-none {
  overflow: unset !important;
}

.offcanvas.offcanvas-end {
  background: #ffffff;
}

.main-info-wrapper {
  .offcanvas-body {
    .body-wrapper {
      h4 {
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;

        /* Headings */
        margin-bottom: 20px;
        color: #8f4300;
      }
    }

    .footer {
      position: fixed;
      background-color: #fff;
      bottom: 20px;

      .inner-footer {
        display: flex;
      }

      .cx-btn-2 {
        margin-right: 7px;
      }

      .cx-btn-2,
      .cx-btn-1 {
        min-width: 60px !important;
        height: 24px !important;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;

      label {
        margin-right: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

        /* Headings */

        color: #8f4300;
      }
    }

    z-index: 22;
  }

  .offcanvas {
    position: relative;
  }

  .info-btns {
    background: #9c4900;
    box-shadow: 0px 4px 4px #cc996b;
    border-radius: 20px 0px 0px 20px;

    position: absolute;
    z-index: 11;
    padding: 7px 10px 7px 15px;
    border: none;
    left: -43px;
    top: 20vh;
  }

  #help-main-wrapper {
    height: calc(100vh - 70px);
    overflow-y: auto;

    .accordion-body {
      padding: 0 10px 10px 10px;
      font-size: 14px;
    }

    .accordion-button:not(.collapsed) {
      background-color: transparent;
      box-shadow: none;
    }

    .accordion-item {
      .accordion-button::after {
        height: 10px;
        width: 10px;
      }

      .accordion-button {
        padding: 10px;
        color: #8f4300;
        font-weight: 600;
        font-size: 15px;

        &::after {
          background-image: url(../images/ic_menu_arrow.svg);
          background-size: 10px;
          background-position: right;
        }

        &:focus {
          border: none;
          box-shadow: none;
        }
      }
    }

    .help-paragraph {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: justify;
      color: #703c19;
    }

    .help-head-sub {
      font-size: 16px;
      font-weight: 600;

      color: #8f4300;
    }

    .help-ol-list {
      list-style: none;

      li {
        color: #8f4300;
      }
    }

    .heading-list {
      font-size: 14px;
      color: #8f4300;
    }
  }
}

.wrapper-info-main {
  .info-btns {
    position: absolute;
    padding: 7px 10px 7px 15px;
    z-index: 20;
    border: none;
    background: #9c4900;
    box-shadow: 0px 4px 4px #cc996b;
    border-radius: 20px 0px 0px 20px;
    right: 0;
    top: 20vh;
  }
}

.offcanvas-backdrop {
  background-color: #421f00;
}

.btn-active-ann {
  background-color: #9c4900;
  color: #fff !important;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border-radius: 10px;
  padding: 7px 20px;
  height: 40px;
  min-width: 140px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 1px solid #f6efe9;
  margin: 0 0.2rem;
}

::-moz-selection {
  /* Code for Firefox */
  color: #fff;
  background: #9c4900cc;
}

::selection {
  color: #fff;
  background: #9c4900cc;
}

.add-vehicle-form-wrapper {
  ::-webkit-file-upload-button {
    display: none;
  }

  ::file-selector-button {
    display: none;
  }
}

.leaflet-popup-content {
  p {
    margin: 0 !important;
    color: rgba(143, 67, 0, 0.8);

    label {
      margin-left: 5px;
      color: #8f4300;
      font-weight: 600;
    }
  }

  .map-poinofIntrest-popup {
  }
}

.dashboard_popup {
  .leaflet-popup-close-button {
    display: none !important;
  }
}

.leaflet-popup-content {
  z-index: 999999;

  .popover-wrapper {
    .pw-top {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      span {
        margin: 15px 0;
        color: #8f4300;
        font-size: 12px;

        img {
          width: 15px;
          margin-right: 5px;
        }

        p {
          display: inline-block;
        }

        .animated_speed {
          display: inline-block;
          animation: wiggle 1.5s ease-in infinite;
        }
      }

      .pw-dropdown {
        position: absolute;
        top: 8px;
        right: 13px;
      }
    }

    .pw-img-box {
      display: flex;
      justify-content: center;
      margin-bottom: 18px;
    }

    .pw-bottom {
      .table > :not(caption) > * > * {
        border: none;
      }

      .pwb-table {
        margin: 0;

        tr {
          td {
            font-size: 12px;
            color: #8f4300;
            padding: 0.2rem;

            &:last-child {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}


.box-login{
  p{
    line-height: 35px;
  }
}






@keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }

  15% {
    transform: rotateZ(-13deg);
  }

  20% {
    transform: rotateZ(9deg);
  }

  25% {
    transform: rotateZ(-10deg);
  }

  30% {
    transform: rotateZ(7deg);
  }

  35% {
    transform: rotateZ(-2deg);
  }

  40%,
  100% {
    transform: rotateZ(0);
  }
}

.ant-picker-dropdown {
  z-index: 9999 !important;
}

.leaflet-control-attribution {
  display: none !important;
}


// GMS Report Css
.color-box {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border: 1px solid #ccc; /* Optional: add border */
  border-radius: 2px; /* Optional: slightly rounded corners */
}

.red {
  background-color: red;
}

.orange {
  background-color: orange;
}

.yellow {
  background-color: yellow;
}

.green {
  background-color: green;
}


.translate-export{
  transform: translate(0,-6px);
}