.CustomerProfile {
  padding: 20px;
  margin-bottom: 10px;
}

.Customer_Profile {
  min-height: calc(100vh - 120px);
}

.CustomerProfile-head {
  background-color: #f6efe9;
  border-radius: 20px;
  width: 100%;
  height: 120px;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 75px;
  // align-items: center;
}

.porile-img {
  position: relative;

  img {
    width: 120px;
    height: 120px;
    position: relative;
    top: 35px;
    border-radius: 50%;
  }

  .invalid-feedback {
    position: absolute;
    bottom: -110px;
  }
}

.main-img-wrapper {
  position: relative;

  .profile-img-wrapper {}

  .camera-img-wrapper {
    position: absolute;
    top: 58%;
    left: 70%;
    width: 50px;
    height: 50px;

    img {
      width: 50px;
      height: 50px;
    }
  }
}

.customer-option {
  .dropdown {
    button {
      background-color: white;
      border: none;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  .btn-check:checked+.btn,
  .btn.active,
  .btn.show,
  .btn:first-child:active,
  :not(.btn-check)+.btn:active {
    background-color: white;
  }

  .dropdown-menu {
    inset: -16px 25px auto auto !important;
    border-radius: 10px 0 10px 10px;
    min-width: 150px;
    border: 1px solid #f6efe9;
    padding: 14px 0px;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    background-color: white !important;
    // color: #8f4300 !important;
    color: rgba(143, 67, 0, 0.6) !important;
    // font-size: 12px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    cursor: default;
  }

  .dropdown-item {
    a:hover {
      color: #8f4300 !important;
    }
  }

  .dropdown-item {
    color: rgba(143, 67, 0, 0.6) !important;
    // font-size: 12px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    padding: 0px 15px 4px 15px !important;

    &:last-child {
      padding: 0px 15px 0px 15px !important;
    }
  }
}

.btns-main {
  justify-content: flex-end;
  display: flex;
}

.information-card {
  margin-bottom: 25px;

  .Heading {
    p {
      font-weight: 400;
      font-size: 20px;
      color: #8f4300;
    }
  }

  .information-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f6efe9;
    padding-bottom: 10px;
    margin-bottom: 10px;

    .imformation-heading {
      p {
        font-weight: 600;
        font-size: 18px;
        color: #8f4300;
        padding: 0;
        margin: 0;
      }
    }

    .edit-information {
      cursor: pointer;
    }
  }

  .information-contain {
    .information-discriptiopn {
      // margin-bottom: 0px;

      .discription-heading {
        margin: 0;
        padding: 0;
        margin-bottom: 5px;
        color: #8f430080;
        font-weight: 500;
      }

      .discription-contain {
        margin: 0;
        padding: 0;
        margin-bottom: 5px;
        color: #8f4300;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

.transport-main {
  padding: 20px;
  display: flex;
  justify-content: space-around;

  .right-section,
  .left-section {
    margin: 0;
    padding: 20px;
    // margin: 5px;
    width: 49.5%;
    background-color: white;
    // height: 200px;
    border-radius: 20px;

    .transport-head {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      // align-items: center;
      margin-bottom: 20px;

      p {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: #8f4300;
        margin: 0;
        padding: 0;
      }

      a {
        font-size: 10px;
        font-weight: 300;
        color: #9c4900 !important;
      }

      img {
        cursor: pointer;
      }
    }
  }
}

.ApplicationStatistics {
  margin-top: 15px !important;
  margin: 0;
  padding: 20px;
  width: 100%;
  background-color: white;
  border-radius: 20px;

  .ApplicationStatistics-head {
    margin-bottom: 20px;

    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: #8f4300;
      margin: 0;
      padding: 0;
    }
  }
}

.road-map {
  margin: 5px 0;
  // height: 386px;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  position: relative;

  .map-main {
    height: calc(100vh - 392px);
  }
}

.common-table {
  max-height: 500px;
  border-radius: 20px;
  padding: 15px;
  border: 1px solid #f6efe9;
  overflow-x: auto;

  table {
    min-width: 450px;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    thead {
      border-bottom: 1px solid #f6efe9;

      #FirstMiunWIdth {
        width: 33% !important;
      }

      #twoTables {
        width: 50%;
      }

      tr {
        th {
          font-size: 14px;
          color: #8f430080;
          font-weight: 400;
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 14px;
          color: #8f4300;
          font-weight: 500;
          padding: 7px 0;

          .tableImg {
            padding-right: 15px;
          }
        }
      }
    }
  }
}

.cameraimg {
  width: 50px !important;
  height: auto !important;
  top: 70px !important;
  right: 40px;
  background-color: white;
}

.custom_height_Form_full {
  height: calc(100vh - 90px) !important;
}

.common-labels {
  font-size: 14px;
  color: #703c19 !important;
  margin-bottom: 5px !important;

  .div {
    span {
      color: red;
    }
  }

  span {
    color: red;
  }
}

.custom-label-title p {
  font-size: 14px !important;
  color: #703c19 !important;
  margin-bottom: 5px !important;
  // margin-right: 3rem !important;

  span {
    color: red;
  }
}

.labelRadio {
  color: rgba(156, 73, 0, 0.5) !important;
  font-size: 14px;
  margin-right: 0.3rem;
}

.textArea-common {
  display: flex;
  flex-direction: column;

  textarea {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: fit-content;
    resize: none;
  }
}

.color-circle {
  width: 20vw;
  height: 20vw;
  background: rgba(156, 73, 0, 0.3);
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  top: 10%;
  right: 40%;
}

// update logo

.topUpdateBtn {
  display: flex;
  justify-content: flex-end;

  button {}
}

.update-logo-main {
  margin: 0 !important;
  padding: 0;
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 220px);
  overflow-y: scroll;

  .update-logo-card {
    background-color: #f6efe9;
    border-radius: 20px;
    padding: 15px;
    margin-bottom: 20px;
    height: 228px;
    width: 32%;

    .update-logo-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(156, 73, 0, 0.1);
      padding: 10px 0;

      p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #8f4300;
        font-weight: 600;
      }

      .backNone .dropdown button {
        background-color: transparent !important;
      }
    }

    .update-logo-img {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 84%;

      img {
        height: 73px;
        width: auto;
        object-fit: cover;
      }
    }
  }
}

.common-model {
  .modal-dialog {
    .modal-content {
      border-radius: 20px;
      padding: 20px;

      .modal-header {
        border-bottom: 1px solid #f6efe9;
        padding: 0 0 10px 0;

        .modal-title {
          color: #8f4300;
          font-size: 20px;
          font-weight: 400;
        }
      }

      .modal-body {
        color: #8f4300;
        font-size: 14px;
        font-weight: 400;
        padding: 20px 0 40px 0;

        .form_input_main {
          margin: 0;
        }
      }

      .modal-footer {
        border-top: 1px solid #f6efe9;

        .btn-wrapper {
          margin-top: 0 !important;
        }
      }

      .pop-up-modal-footer {
        .cx-btn-1 {
          margin-right: 20px;
        }
      }
    }
  }
}

#Customer_Responsive {
  display: flex;
  justify-content: space-between;

  .innerSelectBox {
    width: 20%;
  }

  .exportBTN {
    width: 5%;
  }

  .BTNwidthCustom {
    width: 12%;
  }

  .datepicker-main img {
    right: 25px;
  }
}

// React phone Input css
.react-tel-input .form-control {
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  // width: 0px;
  outline: none;
  padding: 18.5px 14px 18.5px 60px;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
  color: #495057;
}

.CountyListNPM {
  div {
    button {
      padding: 0;
      border: 0.956px solid #f6efe9 !important;
      color: rgb(143, 67, 0) !important;
      font-size: 14px;
      height: 40px;
      border-radius: 10px !important;
      padding-right: 15px;
    }

    .ReactFlagsSelect-module_selectBtn__19wW7:after {
      background-image: url("../images/DDlogo.png") !important;
    }
  }
}

.antTableCounterAndScroll {
  max-height: calc(100vh - 220px);
  overflow: scroll;
}

.antTable table {
  border-collapse: separate;
  /* Required for border-spacing to work */
  border-spacing: 0 20px;
  /* Remove default spacing */
  width: 100%;
  /* Ensure table width is correct */
}

.antTable .ant-table-row,
.antTable .ant-table-thead {
  background-color: #ffffff;
  color: #8f4300;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.antTable .ant-table-tbody .ant-table-row td {
  border-top: 0.5px solid #8f430020;
  border-bottom: 0.5px solid #8f430020;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #fff;
}


.antTable .ant-table-tbody .ant-table-row td:first-child {
  border-top: 0.5px solid #8f430020;
  border-left: 0.5px solid #8f430020;
  border-bottom: 0.5px solid #8f430020;
  border-radius: 8px 0px 0px 8px;
}

.antTable .ant-table-tbody .ant-table-row td:last-child {
  border-top: 0.5px solid #8f430020;
  border-right: 0.5px solid #8f430020;
  border-bottom: 0.5px solid #8f430020;
  border-radius: 0px 8px 8px 0px;
}








.antTable .ant-table-content .ant-table-thead th {
  // border-bottom: 0;
  // border-top: 0.5px solid #8f4300;
  // border-right: 0.5px solid #8f4300;
  // border-bottom: 0.5px solid #8f4300;
  border-bottom: 0;
  color: #8f4300;
  background-color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

}

.ant-table-wrapper .ant-table-column-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.antTable .ant-table-content .ant-table-thead th span .ant-table-column-sorter .active {
  color: #8f4300;
}

.antTable .ant-table-content .ant-table-thead th .active {
  color: #8f4300;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item span {
  color: #8f4300;
}

.ant-checkbox-checked .ant-checkbox-inner {
  color: #8f4300;
  background-color: #8f4300;
  border-color: #8f4300;
}

.ant-btn-link {
  color: #8f4300;
}

// .antTable .ant-table-content .ant-table-thead th:first-child {
//   // border-bottom: 0;
//   border-top: 0.5px solid #8f4300;
//   border-left: 0.5px solid #8f4300;
//   border-bottom: 0.5px solid #8f4300;
//   background: inherit;
//   border-radius: 8px 0px 0px 8px;
// }

// .antTable .ant-table-content .ant-table-thead th:last-child {
//   // border-bottom: 0;
//   border-top: 0.5px solid #8f4300;
//   border-right: 0.5px solid #8f4300;
//   border-bottom: 0.5px solid #8f4300;
//   background: inherit;
//   border-radius: 0px 8px 8px 0px;

// }