.mainVehAccident {
  // margin-bottom: 1rem;

  .row {
    display: flex;
  }

  .innerSelectBox {
    position: relative;

    .ddLogo {
      position: absolute;
      top: 16px;
      right: 10px;
    }
  }

  .innerSelectBox {
    position: relative;

    .ddLogo {
      position: absolute;
      top: 16px;
      right: 10px;
    }

    .calendarLogo {
      position: absolute;
      // top: 0px;
      // right: 0px;
    }

    .addVehCalender {
      position: absolute;
      top: 38px;
      right: 10px;
    }

    .addonCalneder {
      position: absolute;
      top: 38px;
      right: 10px;
    }
  }

  .calendarAcces {
    position: absolute;
    top: 12px;
    right: 10px;
  }

  .calendarLogo {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .addVehCalender {
    position: absolute;
    top: 38px;
    right: 10px;
  }

  .addonCalneder {
    position: absolute;
    top: 42px;
    right: 10px;
  }
}

.addVehCalender {
  position: absolute;
  top: 38px;
  right: 10px;
}

.AddAccidentLink {
  button {
    background: #fff;
    border: none;
    width: 220px;
    height: 40px;
    color: #9c4900;
    font-size: 14px;
    border-radius: 10px;
  }
}

.headerDivIc {
  height: 40px;
  width: 40px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-left: 0.5rem;

  img {
    height: 18px;
  }
}

// Add Vehicle expenses
.addVehicleSe {
  // width: 100%;
  display: flex;
  justify-content: space-between;

  .addVehicleLeftSec {
    width: 49%;
    padding: 10px;

    .weekCounter {
      .headinglabelVehi {
        font-size: 14px;
        color: rgba(156, 73, 0, 0.8) !important;
        margin-bottom: 5px !important;
        opacity: 0.6;
      }
    }
  }

  .imagesRightSec {
    padding: 10px;
    width: 49%;

    .innerSelectBox {
      position: relative;

      .savedInvoice {
        width: 100%;
        border: 0.956px solid #f6efe9;
        padding: 4px;
        border-radius: 10px;
        color: rgba(143, 67, 0, 0.5);

        label {
          padding-left: 30px;
        }
      }

      .invalidText {
        color: red;
        position: absolute;
        top: 35px;
        left: 50%;
      }

      .retry {
        position: absolute;
        top: 35px;
        right: 10%;
        color: rgba(143, 67, 0, 0.5);
      }

      .right {
        position: absolute;
        top: 38px;
        right: 30px;
      }

      .save {
        position: absolute;
        top: 34px;
        left: 5px;
      }

      .Inspect {
        position: absolute;
        top: 34px;
        right: 8px;
      }

      // .customFinp{
      //     &::placeholder{
      //     }
      // }
    }

    ///////////old css of previewImg commented by me //////////////////////////////////////
    // .previewImg {
    //   border: 1px solid #f6efe9;
    //   border-radius: 10px;
    //   height: 254px;
    //   width: 100%;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //  

    //   .InvoiceImg {
    //     width: 180px;
    //     height: 240px;
    //     object-fit: cover;
    //   }
    //  
    // }

    ///////////new  css of previewImg made by me //////////////////////////////////////

    .previewImg {
      border: 1px solid #f6efe9;
      border-radius: 0.625rem;
      /* 10px / 16px */
      // height: 15.875rem; /* 254px / 16px */
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;


      .InvoiceImg {
        width: 11.25rem;
        /* 180px / 16px */
        height: 15rem;
        /* 240px / 16px */
        object-fit: cover;
        margin: 0.5rem;
      }

      .InvoiceImg-2 {
        width: 10rem;
        /* 160px / 16px */
        height: 9.0625rem;
        /* 145px / 16px */
        object-fit: cover;
        margin: 0.5rem;
      }
    }

    // ////////////////////////////////////////////////////////////////
  }
}

// View vehicle accident styles
.headingDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #f6efe9;
  margin-bottom: 1rem;

  .headingTxt {
    p {
      color: #8f4300;
      font-weight: 600;
      font-size: 20px;
    }
  }

  .dropdown-menu {
    inset: -16px 25px auto auto !important;
  }
}

.vertical-stepper-main-wrapper {
  display: flex;
  // justify-content: space-between;
  align-items: flex-start;

  .firstBox {
    margin-right: 60px;

    .pickUp {
      width: 300px;
    }

    .content {
      .headGreen {
        // margin-bottom: 104px;

        color: green;
        font-weight: 500;
        font-size: 15px;
      }

      .headBlack {
        color: #000;
        font-weight: 500;
        font-size: 15px;
      }

      .dateInn {
        color: #9c4900;
        font-size: 14px;
        margin-bottom: 20px;
      }

      .insideDateTime {
        color: #9c4900;
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
  }

  .dateBox1 {
    margin-right: 60px;

    p {
      color: #9c4900;
      font-size: 14px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .dateBox2 {
    .headETA {
      color: #000;
      font-size: 16px;
      margin-bottom: 15px;
      margin-top: 17px;
    }

    p {
      color: #9c4900;
      font-size: 14px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

// Edit vehicle styles
.mainVehAccident {
  .editInvoiceWrapper {
    border: 1px solid #f6efe9;
    position: relative;
    border-radius: 10px;
    width: 100%;
    height: 500px;
    padding: 20px 20px;

    .innerMainInvoice {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 20px !important;
      width: 100%;

      .invoiceImgEdit {
        height: 450px;
        // object-fit: cover;
      }
    }

    .deleteImg {
      position: absolute;
      top: 10px;
      right: 20px;
    }
  }

  .deleteImg {
    position: absolute;
    top: 10px;
    right: 20px;
  }
}

.DetailsSec {
  .head {
    font-size: 14px;
    color: rgba(143, 67, 0, 0.8);
  }

  .Value {
    color: #8f4300;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0.3rem !important;
  }

  // Dispatch module orders view details style
  .DispatchSPan {
    color: #8f4300;
    font-size: 12px;
  }

  .invoiceBox {
    border: 1px solid #f6efe9;
    border-radius: 10px;
    // width: 100%;
    width: fit-content;
    // height: 180px;
    height: auto;
    padding: 10px;

    p {
      color: rgba(143, 67, 0, 0.8);
      margin-bottom: 0.5rem !important;
    }

    .headingInvoi {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.5px solid #f6efe9;
    }

    .saveFile {
      text-align: center;
    }
  }
}

.edatiableInputInvoice {
  outline: none;
  border: none;
  color: #8f4300;
  min-width: 0;
  text-align: center;
}

// Modal invoice
.invoice-model .modal-body {
  padding: 0px !important;

  .invoiceBigImg {
    width: 100%;
    object-fit: cover !important;
  }
}

// DRAG AND DROP FILES CSS WRITTEN HERE
.fileDropper {
  width: 100%;

  .imageHolder {
    cursor: pointer;
    width: 100%;
    height: 80px;
    border: 0.956px dashed rgba(156, 73, 0, 0.5);
    background: #f6efe9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .innerFlex {
      display: flex;
      flex-direction: column;
      justify-content: center !important;
      align-items: center;
    }

    .browseImg {
      height: 32px;
      width: 45px;
      margin-bottom: 0.5rem;
    }

    .innerTxt {
      color: rgba(143, 67, 0, 0.5);
      margin-bottom: 0.5rem !important;
    }

    .browseBtn {
      background: rgba(156, 73, 0, 0.3);
      border-radius: 5px;
      width: 100px;
      height: 30px;
      border: none;
      border-radius: 10px;
      color: #9c4900;
      text-align: center;
      cursor: pointer;
      font-size: 15px;
    }
  }
}

.Holidays_Main {
  .main-master-wrapper {
    overflow: auto;
    height: calc(100vh - 91px);
  }

  .holiday-table {}

  .datepicker-main {
    margin-left: 15px;
  }
}

.Vacations_Main {
  .main-master-wrapper {
    overflow: auto;
    height: calc(100vh - 140px);
  }
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 400ms;
  transition-delay: 600ms;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.displayFlexInp {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  .selectForm {
    width: 17%;

    .innerCust {
      width: 100%;
    }
  }

  .selectForm-1 {
    width: 17%;

    .innerCust {
      width: 100%;
    }
  }
}

.vehicleHead {
  p {
    color: #8f4300;
    font-size: 18px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 0.5px solid #f6efe9;
  }
}

.vehivleBody {
  border-bottom: 0.5px solid #f6efe9;
  margin-bottom: 15px;
}

.edatiable {
  border: 1px dashed #8f4300;
}

.accordian_mainTabs {
  .accordion {
    .accordion-item {
      border-radius: 20px;
      // border: 1px solid red;
      margin-bottom: 20px;

      .accordion-header {
        border-radius: 20px;

        button {
          border-radius: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #8f4300;
        }
      }
    }

    .accordion-button:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }

    .accordion-button:not(.collapsed) {
      background-color: transparent;
    }

    .accordion-button:not(.collapsed)::after {
      background-image: url("../images/dropdown_icon.svg");
      background-size: 15px;
    }

    .accordion-button::after {
      background-image: url("../images/dropdown_icon.svg");
      background-size: 15px;
    }

    .eitatable {
      height: 23px;
      color: #8f4300;
      font-weight: 500;
      font-size: 14px;
      border: 1px dashed #8f4300 !important;
      border-radius: 0 !important;
      padding: 0.375rem 0;
      margin-bottom: 0.3rem;
    }
  }
}

.view_drivers {
  #spacingBetween {
    width: 7% !important;
  }
}

.Vehicle_Accident {
  .tablecard {
    height: calc(100vh - 140px) !important;
  }
}

/*LOADER-1*/

.loader-1 .loader-outter {
  position: absolute;
  border: 4px solid #8f4300;
  border-left-color: transparent;
  border-bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
  animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

.loader-1 .loader-inner {
  position: absolute;
  border: 4px solid #8f4300;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  border-right: 0;
  border-top-color: transparent;
  -webkit-animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
  animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}