.transHeadingIcons {
  .addminBtn {
    width: 160px;
    height: 40px;
    background-color: #fff;
    border-radius: 12px;
    color: #9c4900;
    border: none;
    font-size: 14px;
    font-weight: 500;
  }
}
.heightCalc {
  height: CALC(100vh - 130px);
}
.main-master-wrapper {
  overflow: auto;

  .scroller {
    overflow-x: auto;
  }
  .VA_table {
    height: calc(100vh - 215px);
  }
  .tableAdmin {
    min-width: 1060px;
    border-collapse: separate;
    border-spacing: 0 15px;

    // text-align: center;
    .tableHead tr th {
      color: #703c19;
      font-size: 14px;
      font-weight: 400 !important;
      border-bottom: none !important;
      padding: 5px 20px !important;
    }

    .tableBody {
      tr {
        td {
          border: 0.5px solid #f6efe9;
          border-style: solid none;
          padding: 20px !important;
          border-bottom: 0.5px solid #f6efe9 !important;
          &:nth-child(2) {
            // padding: 10px 0 0 20px !important;
          }
        }

        .DeleteBtn {
          cursor: pointer;
        }

        td:first-child {
          border-left-style: solid;
          border-top-left-radius: 11px;
          border-bottom-left-radius: 11px;
        }

        td:last-child {
          border-right-style: solid;
          border-bottom-right-radius: 11px;
          border-top-right-radius: 11px;
        }
      }
    }
  }
}

// View Component styles
#viewAdministratorProfile {
  .information-card {
    margin: 2.5rem 0 1rem;
  }
}

#modal-map-wrapper {
  position: relative;
  #modal-on-map {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
#modal-on-map {
  .modal-title {
    // p {
    color: #8f4300 !important;
    // }
  }
  .headerFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 16px;
      margin-right: 15px;
      .greenDot {
        height: 10px;
        width: 10px;
        background-color: green;
        margin-right: 10px;
        border-radius: 50%;
      }
      .greyDot {
        height: 10px;
        width: 10px;
        background-color: grey;
        margin-right: 10px;
        border-radius: 50%;
      }
      .redDot {
        height: 10px;
        width: 10px;
        background-color: red;
        margin-right: 10px;
        border-radius: 50%;
      }
    }
  }
}

.mobile-number-input-style {
  .react-tel-input .form-control {
    width: 100% !important;
  }
}
.iframe-wrapoper {
  iframe {
    height: 100vh;
    width: 100%;
  }
}
// .leaflet-interactive{
// cursor: none !important;
// }
