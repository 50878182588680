.react-tel-input .country-list .country-name{
    margin-right: 6px;
    margin-left: 30px;
}
// Custom styles for the PhoneInput component
.form_input_main .country-list .search-box {
    border: 2px solid #8f4300; /* Custom border color */
    font-size: 16px; /* Increase font size */
    padding: 8px; /* Increase padding for better visibility */
    width: 91% !important; 
  }
  
