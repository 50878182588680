.previewImg-s {
    border: 1px solid #f6efe9;
    border-radius: 0.625rem; 
    // width: 10rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
   
  
    .InvoiceImg-s {
      width: 11.25rem; 
      height: 15rem; 
      object-fit: cover;
      margin: 0.5rem;
    }
  
    .InvoiceImg-2-s {
      width: 10rem; 
      height: 9.0625rem; 
      object-fit: cover;
      margin: 0.5rem;
    }
  }

