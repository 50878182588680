#View_report_main {
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #f6efe9;

    .left {
      display: flex;

      .Profile_imfg {
        height: 50px;
        width: 50px;
        margin-right: 15px;
      }

      .contain {
        .heading {
          display: flex;
          align-items: center;
          margin-bottom: 5px;

          p {
            font-size: 14px;
            font-weight: 600;
            color: #421f00;
            margin: 0;
            padding: 0;
            margin-right: 10px;
          }

          img {
            margin-right: 20px;
          }
        }

        .reported_person {
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          p {
            font-size: 14px;
            font-weight: 400;
            color: #c7a180;

            span {
              font-weight: 600;
              color: #8f4300;
            }
          }

          img {
            margin: 0 10px;
          }
        }

        .reportLocation {
          display: flex;

          .path {
            color: #8f4300;
            background-color: #f6efe9;
            font-size: 15px;
            font-weight: 500;
            padding: 0;
            padding: 7px 20px;
            border-radius: 15px;
            margin: 0;
            margin-right: 10px;
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      margin-right: 20px;

      p {
        font-size: 14px;
        font-weight: 400;
        color: #c7a180;
        margin: 0;
        padding: 0;
        margin-right: 10px;
      }

      .custom_report {
        .dropdown-menu {
          transform: translate(10px, 26px) !important;
          width: 150px !important;

          .markAs {
            margin-left: 11px;
            padding-bottom: 5px;
            margin-bottom: 14px;
            border-bottom: 0.5px solid #f6efe9;
            font-size: 12px;
            font-weight: 400;
            color: #8f4300;
          }

          .dropdown-item {
            font-size: 12px !important;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .report_message {
    padding: 20px 0px;
    border-bottom: 1px solid #f6efe9;
    p {
      font-size: 14px;
      font-weight: 400;
      color: #8f4300;
    }
  }

  .Attachments {
    padding: 20px 0;
    border-bottom: 0.5px solid #f6efe9;
    .heading {
      font-size: 14px;
      font-weight: 400;
      color: #8f430080;
      margin-bottom: 10px;
    }

    .files {
      display: flex;

      .single_file {
        position: relative;
        margin-right: 20px;

        .cross {
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
        }
      }
    }
  }

  .btn_report_view {
    margin-top: 20px;
  }
}
.issue_Tooltip {
  padding: 7px 15px;
  color: #8f4300;
  span {
    margin-right: 10px;
  }
}
.months {
  display: flex;
  justify-content: space-between;
  .monts_labels {
    font-size: 10px;
    font-weight: 400;
    color: #9c4900;
  }
  img {
    cursor: pointer;
  }
}
.topChart_Report {
  display: flex;
  justify-content: space-between;

  .containHeading {
    .heading {
      font-size: 20px;
      font-weight: 600;
      color: #8f4300;
    }

    .contain {
      font-size: 14px;
      font-weight: 400;
      color: #c7a180;
    }
  }
  .Chart_types {
    display: flex;
    text-align: end;
    .single_issue {
      margin-right: 45px;
      &:last-child {
        margin-right: 0;
      }
      .count {
        font-size: 14px;
        font-weight: 600;
      }
      .Total {
        color: #8f4300;
      }
      .Pending {
        color: #775dd0;
      }
      .Resolved {
        color: #00e396;
      }
      .OnHold {
        color: #feb019;
      }
      .Rejected {
        color: #ff4560;
      }
      .labels {
        font-size: 10px;
        font-weight: 400;
        color: #c7a180;
      }
    }
  }
}
.exportButton {
  margin-top: -14rem;
}
.heightFixed {
  height: 100vh;
  .UserTabel {
    height: calc(100vh - 200px);
  }
}
// REPORTS COPONENTS STYLES
.taskMain {
  .reportsMain {
    .reportsCards {
      background-color: #fff;
      border-radius: 14px;
      height: 520px;
      // height: min-content;
      .cardHeader {
        padding: 10px;
        background-color: #9c4900;
        h1 {
          font-size: 18px;
          color: #fff;
          margin-bottom: 0px !important;
          font-weight: 500 !important;
        }
      }
      .cardBody {
        padding: 10px 0px;
        .elementsBox {
          display: flex;
          flex-direction: column;
          .innerElements {
            padding: 5px 10px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #dfe1e5;
            i {
              background-color: #9c4900;
              height: 30px;
              width: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 12px;
              margin-right: 15px;
              color: #fff;
            }
            .linkTxt {
              text-decoration: none !important;
              color: #703c19 !important;
              font-weight: 500 !important;
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }
}
