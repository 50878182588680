@import url(./main-responsive.scss);
@import url(./main-responsive.scss);
@import url(./Customer.scss);
@import url(./globalStyle.scss);
@import url(./header.scss);
@import url(./sidebar.scss);
@import url(./dashboard.scss);
@import url(./masterSetting.scss);
@import url(./MasterData.scss);
@import url(auth.scss);
@import url(./administrator.scss);
@import url(./TransportManager.scss);
@import url(./auth.scss);
@import url(./vehicleExpenses.scss);
@import url(./parkingSlot.scss);
@import url(./Chating.scss);
@import url(./TripManagement.scss);
@import url(./dispatch.scss);
@import url(./SadikResponsive.scss);
@import url(./Usama_Responsive.scss);
@import url(./Aameer_responsive.scss);
@import url(./Payment.scss);
@import url(./ConfigurationChecker.scss);
@import url(./SearchFunction.scss);
@import url(./FuelManagementDashbord.scss);
@import url(./UseDashboard.scss);
@import url(./ViewReport.scss);
@import url(./VehicleInspinction.scss);
@import url(./calendar.scss);
@import url(./VehicleAvailability.scss);
@import url(./Driver_Duty_Roaster.scss);

.monthe-list {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -18px;
}

.step {
  margin-bottom: 5px;
}

.pickup-icon,
.drop-icon {
  cursor: pointer;
  // Add any additional styles for the icons
}

.date-time {
  font-size: 11px;
  color: #333; // Adjust color as needed
}

.team-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  .menus-items {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      text-decoration: none;
    }

    // align-items: center;

    .icon-menu-name-arrow {
      display: flex;
      justify-content: space-between;

      .menu-left {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .menu-name {
          padding-left: 10px;
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: var(--gray_08_LightMode);
        }

        .team-image {
          margin: 0 10px 0 3px;

          img {
            width: 100%;
            height: auto;
            border-radius: 50%;
          }
        }
      }

      .arrow {}
    }
  }
}

.team-user-for-team {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px 10px 0px;

  .menus-items {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      text-decoration: none;
    }

    // align-items: center;

    .icon-menu-name-arrow {
      display: flex;
      justify-content: space-between;

      .menu-left {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .menu-name {
          padding-left: 10px;
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: var(--gray_08_LightMode);
        }

        .team-image {
          margin: 0 10px 0 3px;

          img {
            width: 100%;
            height: auto;
            border-radius: 50%;
          }
        }
      }

      .arrow {}
    }
  }
}

.sub-menus-1 {
  margin: 10px 0 20px 30px;
  transition: all 0.5s;
  border-left: 1px solid var(#9c4900);

  a {
    text-decoration: none;
  }

  .sub-menue-items {
    margin-bottom: 15px;
    display: block;
    margin-left: 24px;
  }

  .menu-name {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--gray_05_LightMode);
  }
}

.sub-menus-1-for-team {
  margin: 10px 0 20px 30px;
  transition: all 0.5s;
  border-left: 1px solid var(#9c4900);

  a {
    text-decoration: none;
  }

  .sub-menue-items {
    margin-bottom: 15px;
    display: block;
    margin-left: 24px;
  }

  .menu-name {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgb(17 17 17 / 50%) right
  }
}

.custom-select:hover .ant-select-selector {
  border-color: #f6efe9 !important; /* Change border color to red on hover */
}
.custom-select.ant-select-focused .ant-select-selector {
  border-color: #9C4900 !important; /* Change the border color to red */
}
.custom-select .ant-select-selection-search-input {
  color: #9C4900!important; /* Change text color to #9C4900 */

}


@media (max-width: 699px) {
  .emoji-style {
    bottom: 13rem;
    right: 3%,
  }
}

@media (min-width: 700px) and (max-width: 950px) {
  .emoji-style {
    bottom: 13rem;
    right: 10%,
  }
}

@media (min-width: 951px) and (max-width: 1159px) {
  .emoji-style {
    bottom: 13rem;
    right: 35%,
  }
}

@media (min-width: 1160px) and (max-width: 1350px) {
  .emoji-style {
    bottom: 13rem;
    right: 50%,
  }
}

@media (min-width: 1351px) and (max-width: 1440px) {
  .emoji-style {
    bottom: 13rem;
    right: 55%,
  }
}

@media (min-width: 1441px) and (max-width: 1540px) {
  .emoji-style {
    bottom: 13rem;
    right: 60%,
  }
}

@media (min-width: 1541px) and (max-width: 1730px) {
  .emoji-style {
    bottom: 13rem;
    right: 65%,
  }
}

@media (min-width: 1731px) and (max-width: 1980px) {
  .emoji-style {
    bottom: 13rem;
    right: 70%,
  }
}

@media (min-width: 1981px) and (max-width: 2550px) {
  .emoji-style {
    bottom: 13rem;
    right: 75%,
  }
}

@media (min-width: 2501px) {
  .emoji-style {
    bottom: 13rem;
    right: 78%,
  }
}

@media (max-width: 699px) {
  .emoji-style-sidebar-off {
    bottom: 13rem;
    right: 6%,
  }
}

@media (min-width: 700px) and (max-width: 950px) {
  .emoji-style-sidebar-off {
    bottom: 13rem;
    right: 13%,
  }
}

@media (min-width: 951px) and (max-width: 1159px) {
  .emoji-style-sidebar-off {
    bottom: 13rem;
    right: 40%,
  }
}

@media (min-width: 1160px) and (max-width: 1400px) {
  .emoji-style-sidebar-off {
    bottom: 13rem;
    right: 58%,
  }
}

@media (min-width: 1401px) and (max-width: 1490px) {
  .emoji-style-sidebar-off {
    bottom: 13rem;
    right: 62%,
  }
}

@media (min-width: 1491px) and (max-width: 1590px) {
  .emoji-style-sidebar-off {
    bottom: 13rem;
    right: 65%,
  }
}

@media (min-width: 1591px) and (max-width: 1780px) {
  .emoji-style-sidebar-off {
    bottom: 13rem;
    right: 70%,
  }
}

@media (min-width: 1781px) and (max-width: 2030px) {
  .emoji-style-sidebar-off {
    bottom: 13rem;
    right: 75%,
  }
}

@media (min-width: 2031px) and (max-width: 2550px) {
  .emoji-style-sidebar-off {
    bottom: 13rem;
    right: 77%,
  }
}

@media (min-width: 2551px) {
  .emoji-style-sidebar-off {
    bottom: 13rem;
    right: 82%,
  }
}