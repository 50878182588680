.main-master-wrapper {
  .AddNewForm {
    border: 1px solid #f6efe9;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 0.5rem;

    
  .addCustomerDispatch{
    position: relative;

    img {
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 50%;
    }

    .map-main {
      height: 213px;
      border-radius: 20px;
    }
  }
  }

  .innerWrapper {
    margin-bottom: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #f6efe9;

    // align-items: center;
    .FormHeading {
      p {
        color: #9c4900;
        font-size: 18px;
        padding-bottom: 5px;
      }
    }
  }

  .AddNewBtn {
    border: none;
    width: 98.52px;
    height: 30px;
    background: #9c4900;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
  }

  .stepperInside {
    padding: 15px;
    display: flex;
    justify-content: space-between;

    .Step1 {
    }
  }
}

// Map styles
.DispatchMenuMap {
  .mainMape {
    background-color: #fff;
    width: 100%;
    height: 50vh;
    border-radius: 12px;
    padding: 15px 15px 40px 15px;
    position: relative;

    .map-main {
      height: 100%;
      margin-bottom: 0.5rem;
    }

    .imgFill {
      position: absolute;
      top: 80px;
      height: 30px;
      width: 30px;
      right: 65%;
    }

    .leaflet-map {
      height: 44vh;
    }

    .imgLine {
      position: absolute;
      top: 140px;
      height: 30px;
      width: 30px;
      right: 50%;
    }

    .flagComments {
      display: flex;
      flex-wrap: wrap;
      margin-top: 4px;

      img {
        height: 23px;
        width: 23px;
      }

      label {
        color: #9c4900;
        font-size: 14px;
      }
    }
  }

  .mainMapeOrdr {
    background-color: #fff;
    width: 100%;
    height: 74vh;
    border-radius: 12px;
    padding: 15px 15px 40px 15px;
    position: relative;

    .leaflet-container.leaflet-touch.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom {
      height: 68vh;
    }

    .map-main {
      height: 100%;
      margin-bottom: 0.5rem;
    }

    .imgFill {
      position: absolute;
      top: 80px;
      height: 30px;
      width: 30px;
      right: 65%;
    }

    .leaflet-map {
      height: 44vh;
    }

    .imgLine {
      position: absolute;
      top: 140px;
      height: 30px;
      width: 30px;
      right: 50%;
    }

    .flagComments {
      display: flex;
      flex-wrap: wrap;
      margin-top: 4px;

      img {
        height: 23px;
        width: 23px;
      }

      label {
        color: #9c4900;
        font-size: 14px;
      }
    }
  }
}

#mapManualRouting {
  .map-main {
    height: calc(100vh - 175px);
  }

  .bottom-wrapper {
    .bottom-status {
      span {
        color: #8f4300;
      }
    }
  }
}

.manualROuting {
  .mainMape {
    background-color: #fff;
    width: 100%;
    height: calc(100vh - 90px);
    border-radius: 12px;
    padding: 20px;
    position: relative;

    // .map-main {
    //   height: 100%;
    // }

    .headingIcons {
      position: absolute;
      top: 25px;
      right: 70px;
      display: flex;

      .backColoricon {
        background-color: #9c4900;
        height: 35px;
        width: 35px;
        border-radius: 12px;
        display: flex;
        margin: 0.5rem;
        justify-content: center;
        align-items: center;
      }
    }

    .firstHotspot {
      position: absolute;
      top: 140px;
      left: 180px;
      width: 10vw;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      background-color: transparent;

      .firstpopUp {
      }

      .hotSpotBtn {
        background-color: transparent;
        border: none;

        img {
          height: 55px;
          width: 55px;
          cursor: pointer;
        }
      }
    }

    .hotspotImgs {
      img {
        height: 55px;
        width: 55px;
        cursor: pointer;
      }

      .RedHot1 {
        position: absolute;
        top: 100px !important;
        left: 10%;
      }

      .firstpopUp {
        position: absolute;
        top: 20px;
      }

      .RedHot2 {
        position: absolute;
        top: 200px;
        left: 25%;
      }

      .RedHot3 {
        position: absolute;
        top: 300px;
        left: 20%;
      }

      .RedHot4 {
        position: absolute;
        top: 280px;
        left: 40%;
      }

      .YellowHot1 {
        position: absolute;
        top: 110px;
        right: 20%;
      }

      .YellowHot2 {
        position: absolute;
        top: 140px;
        right: 35%;
      }

      .YellowHot3 {
        position: absolute;
        top: 350px;
        right: 18%;
      }

      .YellowHot4 {
        position: absolute;
        top: 240px;
        right: 40%;
      }
    }
  }
}

// firstHotspot
// #firstHotspot {
.popover-main-wrapperHot {
  width: 28rem !important;
  max-width: 30rem !important;
  border: none !important;
  box-shadow: 0 3px 10px 5px rgb(128 128 128 / 20%);

  .pm-body {
    .headingHot {
      p {
        color: #9c4900;
        font-size: 18px;
        border-bottom: 0.5px solid #f6efe9 !important;
      }
    }

    .popupRow {
      border-bottom: 0.5px solid #f6efe9 !important;
      margin-bottom: 0.5rem;
    }

    .innerSelectBox {
      position: relative;

      .DatePlus {
        position: absolute;
        top: 36px;
        right: 10px;
      }
    }
  }
}

#View_Dispatch_main {
  .headingDetails {
    .AddAccidentLink {
      background-color: #8f4300;
      color: #fff;
      border: none;
      // padding: 10px;
      height: 40px;
      width: 150px;
      border-radius: 12px;
    }
  }

  .heading,
  .Value {
    font-weight: 400 !important;
  }

  .DetailsSec .head {
    color: rgba(143, 67, 0, 0.5);
  }
}

// Vehicle Booking Styles
.vehicle_Booking {
  .main-master-wrapper {
    .header {
      border-bottom: 0.5px solid #f6efe9;
      margin-bottom: 20px;

      .headerTxt {
        padding-bottom: 20px;
        font-weight: 400;
        font-size: 20px;
        color: #8f4300;
      }
    }

    .vehicleMainRow {
      .colForm {
        label {
          color: rgba(143, 67, 0, 0.5);
          font-size: 14px;
        }

        margin-bottom: 20px;
      }
    }

    .detailsSec {
      padding: 20px;
      background-color: #fff;
      border: 1px solid #f6efe9;
      border-radius: 10px;
      margin-bottom: 20px;

      .headerDet {
        border-bottom: 0.5px solid #f6efe9;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        .custom_address {
          align-items: center;
        }

        .headerTxtDet {
          padding-bottom: 10px;
          font-weight: 400;
          font-size: 18px;
          color: #8f4300;
        }

        .defult-adrs-txt {
          font-size: 14px;
          font-weight: 400;
          color: #8f430080;
        }
      }

      .colFormDet {
        margin-bottom: 20px;
      }
    }
  }
}

.warehouse_details {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #f6efe9;
  border-radius: 10px;
  margin-bottom: 20px;

  .headerDet {
    border-bottom: 0.5px solid #f6efe9;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .custom_address {
      align-items: center;
    }

    .headerTxtDet {
      padding-bottom: 10px;
      font-weight: 400;
      font-size: 18px;
      color: #8f4300;
    }

    .defult-adrs-txt {
      font-size: 14px;
      font-weight: 400;
      color: #8f430080;
    }
  }

  .colFormDet {
    margin-bottom: 20px;
  }

  .addmerchant {
    position: relative;

    img {
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 50%;
    }

    .map-main {
      height: 213px;
      border-radius: 20px;
    }
  }
}

.heading_warehoues {
  font-size: 20px;
  font-weight: 400;
  color: #8f4300;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 10px;
}

.Single_listing {
  cursor: pointer;
  background-color: white;
  padding: 8px 20px;
  border-radius: 20px;
  margin-bottom: 10px;

  .warehoue-heading {
    color: #8f430080;
    font-size: 14px;
    font-weight: 400;
  }

  .warehoue-contain {
    color: #8f4300;
    font-size: 14px;
    font-weight: 600;
  }
}

.Single_listing_active {
  cursor: pointer;
  background-color: #8f4300;
  padding: 8px 20px;
  border-radius: 20px;
  margin-bottom: 10px;

  .warehoue-heading {
    color: white;
    font-size: 14px;
    font-weight: 400;
  }

  .warehoue-contain {
    color: white;
    font-size: 14px;
    font-weight: 600;
  }
}

// Union
.list_wrapper {
  // border: 1px solid red;
  height: calc(100vh - 260px);
  overflow-y: auto;
}

.viewmerchant {
  position: relative;

  .custom-tooltip {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;

    .box {
      background-image: url("../images/Union.svg");
      background-position: center;
      background-repeat: no-repeat;
      height: 40px;
      width: 200px;

      p {
        font-size: 12px;
        font-weight: 400;
        color: #8f4300;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        margin-top: 7px;
      }
    }

    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      height: 40px;
      width: auto;
    }
  }

  .map_main_warehouse {
    .map-main {
      height: 480px;
      border-radius: 20px !important;
    }

    .leaflet-container {
      border-radius: 20px;
    }
  }
}

.vertical-stepper-main-wrapper {
  .step {
    padding: 10px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    background-color: cream;
  }

  .v-stepper {
    position: relative;
    /*   visibility: visible; */
  }

  /* regular step */
  .step .circle {
    background-color: white;
    border: 1px solid gray;
    border-radius: 100%;
    width: 30px;
    /* +6 for border */
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .step .line {
    top: 29px;
    left: 13px;
    /*   height: 120px; */
    height: 100%;

    position: absolute;
    border-left: 3px solid rgba(143, 67, 0, 0.5);
  }

  .step.completed .circle {
    visibility: visible;
    // background-color: rgb(6, 150, 215);
    border-color: #9c4900;

    img {
      height: 20px;
      width: 20px;
    }
  }

  .step.completed .line {
    border-left: 3px solid #9c4900;
  }

  .step.active .circle {
    visibility: visible;
    border-color: rgb(6, 150, 215);
  }

  .step.empty .circle {
    visibility: hidden;
  }

  .step.empty .line {
    /*     visibility: hidden; */
    /*   height: 150%; */
    top: 0;
    height: 150%;
  }

  .step:last-child .line {
    border-left: 3px solid white;
    z-index: -1;
    /* behind the circle to completely hide */
  }

  .content {
    margin-left: 20px;
    display: inline-block;

    label {
      color: #8f4300;
      font-size: 18px;
      font-weight: 600;
    }

    .Value {
      color: #8f4300;
      font-size: 14px;
      margin-bottom: 10px;
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      color: #8f4300;
      font-size: 12px;
    }

    .innerSteeper {
      display: flex;
      flex-direction: column;
      transition: all 0.5s ease;
      position: relative;

      .innerLine {
        height: 50%;
        width: 2px;
        background-color: #8f4300;
        position: absolute;
        left: 29px;
        top: 1px;
      }

      .steperBox {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .inncircle {
          margin-left: 20px;
          background-color: #8f4300;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          z-index: 10;

          label {
            background-color: #fff;
            height: 6px;
            width: 6px;
            border-radius: 50%;
          }
        }

        .innStepLab {
          font-size: 14px;
          color: #8f4300;
          font-weight: 300;
        }
      }
    }
  }
}

.Dispatch_Order {
  width: 100%;

  .mainOrderMap {
    // position: relative;
    width: 100% !important;
    height: 100vh !important;
    // background-color: red;
    // .map-main .leaflet-touch-zoom {
    //   position: fixed !important;

    // }
  }

  .searchMainBox {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .searchsInsider {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      width: 100%;

      .innerSelectBox {
        width: 30%;
        margin-right: 20px;

        // &:last-child {
        //   margin-right: 0px;
        // }
      }
    }
  }

  #dispatchTabs {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    .nav-pills {
      display: flex;
      // justify-content: space-between;
      width: 100%;
    }

    .nav-pills .nav-link {
      color: #8f4300 !important;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 40px;
    }

    .nav-item {
      width: 20%;
      text-align: center;
      background-color: #f6efe9;
      border: 1px solid #8f4300;
      border-radius: 12px;
      margin-right: 30px;
      min-height: 40px;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
      background-color: #8f4300 !important;
      color: #fff !important;
      border-radius: 12px;
      border-bottom: none !important;
      border: none !important;
      min-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .nav-pills .nav-link.active > .nav-item {
    }
  }
}
@media (min-width: 991px) and (max-width: 1002px) {
  .resp_correction {
    margin-top: 10px;
  }
}
