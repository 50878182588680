.main-dashboard-wrapper {
  background-color: #fff;
  width: 100%;
  // min-height: calc(100vh - 120px);
  border-radius: 20px;
  padding: 10px 0 0px 10px;
  position: relative;

  .dashboard-main-map {
    .dashboard-inner-wrapper {
      display: flex;
    }
  }

  .map-main {
    height: calc(100vh - 160px);
    position: relative;
    overflow-y: auto;
  }

  .topsection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.7rem;

    p {
      margin: 0;
      color: #8f4300;
      font-weight: 600;
    }
  }

  // Google Map styles.
  .map-wrapper {
    width: 100%;
    // height: calc(100vh - 250px);
    // background-color: rgb(255, 205, 205);
    border-radius: 20px;
    // float: left;
    position: relative;

    // .my-component {
    //   /* Base styles for the component */
    //   width: 200px;
    //   height: 200px;
    //   background-color: blue;
    //     // transform: rotate(270deg) !important;
    // }

    // .rotate {
    //   /* Styles for rotating the component */
    //   transition: transform 0.3s ease;
    // }

    // .rotate-45 {
    //   transform: rotate(45deg);
    // }

    // .rotate-90 {
    //   transform: rotate(270deg);
    // }
    // .rotate-270 {
    //   transform: rotate(270deg);
    // }

    .search-bar-wrapper {
      width: calc(100% - 40px);
      position: absolute;
      top: 20px;
      left: 20px;

      .search-input.form-control {
        border-radius: 10px;
        padding-right: 40px !important;
      }

      .search-wrapper {
        width: 100%;
        height: 40px;
        overflow-y: scroll;
        border-radius: 10px;
        border: 1px solid #f6efe9;
        background-color: #fff;
        box-shadow: 4px 4px 10px rgba(66, 31, 0, 0.2);

        input {
          padding-right: 40px !important;

          &::placeholder {
            color: #9c490080;
            font-size: 12px;
          }
        }

        .searchBox {
          color: #8f4300;
          width: 100%;
          padding: 0px 4px;
          background-image: url(../images/search_icon.svg);
          background-repeat: no-repeat;
          background-position: calc(100% - 12px) center;
          background-size: 20px;
          padding-left: 10px;

          &:focus-within {
            padding-left: 10px;
          }
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .optionListContainer {
        display: none !important;
      }

      .search-btn {
        position: absolute;
        right: 10px;
        border: none;

        &:hover {
          background-color: transparent;
        }
      }
    }

    .search-icon {
      position: absolute;
      right: 10px;
      top: 9px;
      height: 20px;
    }

    .red-car-wrapper {
      position: absolute;
      top: 20%;
      // left: 13rem;
      width: 8vw;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      background-color: transparent;

      .redcar {
        transform: rotate(-30deg);
        cursor: pointer;
      }

      img {
        height: 50px;
        width: 50px;
      }

      button {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        background: transparent;
        border: none;
      }
    }

    .yellow-car-wrapper {
      position: absolute;
      top: 20%;
      left: 20%;
      width: 8vw;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      background-color: transparent;

      .yellowcar {
        transform: rotate(45deg);
        cursor: pointer;
      }

      img {
        height: 50px;
        width: 50px;
      }

      button {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        background: transparent;
        border: none;
      }
    }

    .green-car-wrapper {
      position: absolute;
      bottom: 50px;
      left: 200px;
      width: 8vw;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      background-color: transparent;

      .greencar {
        transform: rotate(5deg);
        cursor: pointer;
      }

      img {
        height: 50px;
        width: 50px;
      }

      button {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        background: transparent;
        border: none;
      }
    }

    .black-car-wrapper {
      position: absolute;
      bottom: 40%;
      right: 30%;
      width: 8vw;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      background-color: transparent;

      .greencar {
        transform: rotate(5deg);
        cursor: pointer;
      }

      img {
        height: 50px;
        width: 50px;
      }

      button {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        background: transparent;
        border: none;
      }
    }

    .blue-car-wrapper {
      position: absolute;
      bottom: 25%;
      left: 25%;
      width: 8vw;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      background-color: transparent;

      .roundedBg {
        transition: all 0.3s ease-in-out;
        background: radial-gradient(
          circle,
          rgba(156, 73, 0, 1) 20%,
          rgba(156, 73, 0, 0.6) 50%,
          rgba(156, 73, 0, 0.5) 50%
        );
        height: 80px;
        width: 80px;
        border-radius: 50%;
        border: none;
      }

      .greencar {
        transform: rotate(185deg);
        cursor: pointer;
      }

      img {
        height: 50px;
        width: 50px;
      }

      button {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        background: transparent;
        border: none;
      }
    }

    .red-bike-wrapper {
      position: absolute;
      top: 30%;
      right: 5%;
      width: 8vw;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      background-color: transparent;

      .greencar {
        transform: rotate(5deg);
        cursor: pointer;
      }

      img {
        height: 50px;
        width: 50px;
      }

      button {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        background: transparent;
        border: none;
      }
    }

    .yellow-bike-wrapper {
      position: absolute;
      bottom: 45%;
      right: 30%;
      width: 8vw;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      background-color: transparent;

      .greencar {
        transform: rotate(185deg);
        cursor: pointer;
      }

      img {
        height: 50px;
        width: 50px;
      }

      button {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        background: transparent;
        border: none;
      }
    }

    .green-bike-wrapper {
      position: absolute;
      bottom: 50%;
      right: 30%;
      width: 8vw;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      background-color: transparent;

      .greencar {
        transform: rotate(45deg);
        cursor: pointer;
      }

      img {
        height: 50px;
        width: 50px;
      }

      button {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        background: transparent;
        border: none;
      }
    }

    .black-bike-wrapper {
      position: absolute;
      top: 92px;
      left: 170px;
      width: 8vw;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      background-color: transparent;

      .roundedBg {
        transition: all 0.3s ease-in-out;
        background: radial-gradient(
          circle,
          rgba(156, 73, 0, 1) 20%,
          rgba(156, 73, 0, 0.6) 50%,
          rgba(156, 73, 0, 0.5) 50%
        );
        height: 80px;
        width: 80px;
        border-radius: 50%;
        border: none;
      }

      .greencar {
        transform: rotate(85deg);
        cursor: pointer;
      }

      img {
        height: 50px;
        width: 50px;
      }

      button {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        background: transparent;
        border: none;
      }
    }

    .blue-bike-wrapper {
      position: absolute;
      top: 92px;
      right: 40%;
      width: 8vw;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      background-color: transparent;

      .greencar {
        transform: rotate(185deg);
        cursor: pointer;
      }

      img {
        height: 50px;
        width: 50px;
      }

      button {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        background: transparent;
        border: none;
      }
    }
  }

  .bottom-wrapper {
    // background-color: #8f4300;
    // position: absolute;
    margin-top: 10px;
    bottom: 5px;
    display: flex;

    .bottom-status {
      margin-right: 1rem;

      img {
        width: 28px;
        margin-right: 0.2rem;
      }

      span {
        vertical-align: middle;
        color: #8f4300;
        font-size: 12px;
      }
    }
  }

  .right-vehicle-status-wrapper {
    width: 60px;
    height: calc(100vh - 250px);
    // float: left;
    position: relative;

    .vehicle1 {
      background-color: #fff;

      &:focus-visible {
        outline: none !important;
      }
    }

    .vehicle-tabs {
      // border: 1px solid transparent;
      background-color: #fff !important;
      position: relative;
      margin: 0;

      a:-webkit-any-link:focus-visible {
        outline-offset: unset !important;
      }

      &:focus-visible {
        outline: none !important;
      }

      &:focus-visible {
        outline-offset: unset !important;
      }

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: -2px;
        transform: translateX(-50%);
        width: 100%;
        height: 1px;
        background-color: #f6efe9;
        z-index: 99;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 8px 15px;

        img {
          width: 20px;
        }

        // .active {
        //   border: 0 !important;
        // }
        a:-webkit-any-link:focus-visible {
          outline-offset: unset !important;
        }

        &:focus-visible {
          outline: none !important;
        }

        &:focus-visible {
          outline-offset: unset !important;
        }
      }

      span {
        color: #8f4300;
        font-size: 12px;
        width: 26px;
        text-align: center;
      }

      .active {
        background-color: #fff !important;
        border-radius: 0 10px 10px 0;
        border: 1px solid #f6efe9;
        border-left: 1px solid transparent;

        a:-webkit-any-link:focus-visible {
          outline-offset: unset !important;
        }

        &:focus-visible {
          outline: none !important;
        }

        &:focus-visible {
          outline-offset: unset !important;
        }
      }
    }

    .dashboard-first-item-active {
      .style-vehicle-btn4 {
        width: 20px !important;
      }
    }

    .tools-main-tab-active {
      transition: all 0.3s ease;

      z-index: 1 !important;

      padding: 10px !important;
      width: 8rem !important;
    }

    #vehicle-main-tab-active {
      .style-vehicle-btn1,
      .style-vehicle-btn2,
      .style-vehicle-btn23,
      .style-vehicle-btn24,
      .style-vehicle-btn25,
      .style-vehicle-btn26,
      .style-vehicle-btn3 {
        width: 20px;
      }

      padding: 10px;
      width: 385px;

      .va-link {
        z-index: 1;
      }
    }

    .vehicle-main-tab {
      position: absolute;
      transition: all 0.3s ease;
      top: 0px;
      right: 57px;
      width: 0;
      height: calc(100vh - 315px);
      background-color: #fff !important;
      border-radius: 20px 0 20px 20px;
      // border: 1px solid #f6efe9;
      // padding: 10px;
      box-shadow: rgb(50 50 93 / 25%) -7px 11px 27px -5px,
        rgb(0 0 0 / 30%) 0px 8px 16px -8px;

      .active {
        background-color: #fff !important;
        border-radius: 0 0 20px 20px;
      }
    }

    .tools-main-tab {
      position: absolute;
      top: -75px;
      transition: all 0.3s ease;

      right: 58px;
      width: 0;
      z-index: -1;
      height: 6rem;
      background-color: #fff !important;
      border-radius: 20px;
      border-top: 1px solid #f6efe9;
      padding: 0;
      display: flex;
      align-items: center;
      box-shadow: rgb(50 50 93 / 25%) -7px 11px 27px -5px,
        rgb(0 0 0 / 30%) 0px 8px 16px -8px;

      .active {
        background-color: #fff !important;
        border-radius: 0 0 10px 10px;
      }

      .tools-wrapper {
        ul {
          transform: translateY(8px);
          // padding-top: 1rem;
          // display: flex;
          // justify-content: center;
          // flex-direction: column;
          padding-left: 5px;

          li {
            list-style-type: none;
            font-size: 12px;
            color: #8f4300;
            margin-bottom: 5px;
          }
        }
      }
    }

    .va-nav {
      border-bottom: 1px solid #f6efe9;
      display: flex;
      justify-content: space-between;

      .nav-link::after {
        transform: scaleX(0);
        // transform: translateX(0);
        transform-origin: bottom right;
      }

      .nav-link::after {
        content: " ";
        display: block;
        border-radius: 10px !important;
        position: absolute;
        // top: 0;
        height: 2px;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        inset: unset;
        background: #9c4900 !important;
        z-index: -1;
        transition: transform 0.3s ease;
      }

      .nav-link.active::after {
        transform: scaleX(1);
        // transform: translateX(100%);

        transform-origin: top left;
      }

      .nav-tabs .nav-link.active {
        // transition: transform 0.3s ease;
        border-bottom: 0.5px solid #9c4900 !important;

        background-color: white !important;
        color: #8f4300;

        &:focus-visible {
          border: none;
        }
      }

      #diffWidth {
        width: 100%;
      }

      .va-tab {
        transition: all 0.3s ease-in-out;
        border-radius: 0 !important;

        .va-link {
          color: #8f430080 !important;
          font-size: 12px;
          position: relative;
          z-index: -1;
        }

        .active {
          border-radius: 0 !important;
          color: #8f4300 !important;
          // border-bottom: 0.5px solid #8f4300;
          transition: all 0.3s ease-in-out;
        }
      }
    }

    .running-status-card-wrapper {
      // margin-top: .2rem;
      height: calc(100vh - 365px);
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .status-card {
        margin: 2px 0;
        padding: 5px 10px;
        font-size: 12px;
        border: 1px solid #f6efe9;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.7s ease-in-out;

        .row {
          .col-md-4 {
            &:nth-child(1) {
            }

            &:nth-child(2) {
            }

            &:nth-child(3) {
              // text-align: right;
            }

            &:nth-child(6) {
              // text-align: right;
            }
          }
        }

        label {
          color: rgba(143, 67, 0, 0.8);
          font-size: 10px;
        }

        p {
          color: var(--mainHeadingDarkColor);
          font-weight: 600;
          margin: 0;
          font-size: 12px;
        }

        &:hover {
          background-color: rgba(66, 31, 0, 1) !important;
          transition: all 0.7s ease-in-out;

          label {
            color: rgba(246, 239, 233, 1) !important;
          }

          p {
            color: rgba(246, 239, 233, 1) !important;
          }
        }
      }

      // .active {
      //   background-color: rgba(66, 31, 0, 1) !important;
      //   label {
      //     color: rgba(246, 239, 233, 1) !important;
      //   }
      //   p {
      //     color: rgba(246, 239, 233, 1) !important;
      //   }
      // }
    }

    .no-trips-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      img {
      }

      p {
        color: #8f4300;
        font-size: 12px;
      }
    }
  }
}

.popover-main-wrapper {
  width: 20rem !important;
  max-width: 25rem !important;
  border: none !important;
  // box-shadow: 0 3px 10px 5px rgb(128 128 128 / 30%);

  .pm-body {
    padding: 10px;
  }

  .popover-wrapper {
    .pw-top {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      span {
        margin: 15px 0;
        color: #8f4300 !important;
        font-size: 12px;

        img {
          width: 15px;
          margin-right: 5px;
        }
      }

      .pw-dropdown {
        position: absolute;
        top: 2px;
        right: 8px;
      }
    }

    .pw-img-box {
      display: flex;
      justify-content: center;
      margin: 0 0 1rem;
    }

    .pw-bottom {
      .table > :not(caption) > * > * {
        border: none;
      }

      .pwb-table {
        margin: 0;

        tr {
          td {
            font-size: 12px;
            color: #8f4300;
            padding: 0.2rem;

            &:last-child {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

.pw-dropdown {
  button {
    padding: 0px;
  }

  // position: absolute !important;
  // top: -10px;
  // right: -10px;

  .dropdown-toggle {
    background-color: transparent !important;
    border: none !important;

    &::after {
      display: none !important;
      border: none;
    }
  }

  .pwd-menu {
    min-width: 5rem !important;
    border: none;
    background-color: #fff;
    width: 10rem;
    transform: translate(-105px, 15px) !important;
    border-radius: 10px 0 10px 10px !important;
    box-shadow: 4px 4px 10px rgba(191, 191, 191, 0.3);

    a {
      font-size: 12px;
      color: #00000080 !important;

      &:hover {
        color: #140c04;
        background-color: transparent;
      }
    }
  }
}

.status-card {
  // padding-top: 2rem !important;
  position: relative;

  .first-active-card-main {
    // display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    .data-content {
      padding: 0px 10px;
    }
  }

  .pw-dropdown {
    // position: absolute;
    // top: 0px;
    // right: 5px;

    .pwd-menu {
      transform: translate(-23px, 15px) !important;
    }
  }
}

iframe {
  border-radius: 20px !important;
}

.dropdown-wrapper {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  // margin-right: 1rem;
  .row {
    margin: 0 0.5rem;
  }

  &:nth-child(3) {
    .multi-select-1 {
      margin-right: 0 !important;
    }
  }

  .form-select {
    width: 13rem;
    margin-right: 1rem;
    box-shadow: none;
    // &:nth-child(1) {
    //   margin-right: 1rem;
    // }
    // &:nth-child(2) {
    // }
  }
}

// For Applying Border Radius to Google Map
.map-main > div > div {
  border-radius: 10px;
}

.dashboard-first-item {
  position: relative;

  .style-vehicle-btn1 {
    position: absolute;
    height: 51px;
    top: -7px;
    z-index: 2;

    right: -28px;
    width: 0;
    background-color: #fff;
  }

  .style-vehicle-btn2 {
    position: absolute;
    height: 52px;
    top: 45px;
    right: -28px;
    z-index: 2;

    width: 0;
    background-color: #fff;
  }

  .style-vehicle-btn3 {
    position: absolute;
    height: 52px;
    z-index: 2;

    top: 99px;
    right: -28px;
    width: 0;
    background-color: #fff;
  }
  .style-vehicle-btn23 {
    position: absolute;
    height: 52px;
    z-index: 2;

    top: 43px;
    right: -28px;
    width: 0;
    background-color: #fff;
  }
  .style-vehicle-btn24 {
    position: absolute;
    height: 52px;
    z-index: 2;

    top: 96px;
    right: -28px;
    width: 0;
    background-color: #fff;
  }
  .style-vehicle-btn25 {
    position: absolute;
    height: 52px;
    z-index: 2;

    top: 150px;
    right: -28px;
    width: 0;
    background-color: #fff;
  }
  .style-vehicle-btn26 {
    position: absolute;
    height: 52px;
    z-index: 2;

    top: 203px;
    right: -28px;
    width: 0;
    background-color: #fff;
  }
  .style-vehicle-btn4 {
    position: absolute;
    height: 52px;
    z-index: 2;
    top: -53px;
    right: 44px;
    width: 0;
    z-index: 10;
    background-color: #fff;
  }
}

.right-tabs-example-tab-first {
  &:focus {
    outline-offset: unset !important;
  }

  &:focus-visible {
    outline: none !important;
  }
}
@media (min-width: 769px) and (max-width: 1800px) {
  .margin-lft-response {
    margin-left: -20px;
  }
  .margin-rgt-response {
  }
}
.tabs-custom-width-2-33 {
  .nav-item {
    width: 33.33%;
    text-align: center;

    .nav-link {
      border-radius: 0 !important;
      transition: all 0.3s ease-in-out;
    }
  }
}

.tabs-custom-width-2-50 {
  .nav-item {
    width: 50%;
    text-align: center;
    transition: all 0.3s ease-in-out;
    border-radius: 0 !important;

    .nav-link {
      border-radius: 0 !important;
      transition: all 0.3s ease-in-out;
    }
  }
}

.multi-select-1 {
  margin-right: 0;

  // After issues styles given by aameer of antDesign multiselect
  .ant-select-selector {
    height: 40px;
    border: 1px solid rgba(246, 239, 233, 1) !important;
  }

  .ant-select-focused:where(
      .css-dev-only-do-not-override-1wazalj
    ).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    )
    .ant-select-selector {
    box-shadow: none !important;
  }

  .ant-select-item .ant-select-item-option {
    font-size: 15px;
    color: red !important;
  }

  .ant-space {
    // font-size: 15px;
    // color: red !important;
  }

  .ant-select-selection-placeholder {
    font-size: 15px;
    color: rgba(156, 73, 0, 0.5);
  }

  // width: 300px;
  .p-multiselect-label {
    font-size: 12px;
    color: rgba(156, 73, 0, 0.5);
  }

  .p-multiselect-item.p-highlight {
    background: transparent !important;
  }

  .p-checkbox .p-checkbox-box {
    border: 1px solid #9c4900;
    border-radius: 1px;
  }

  .p-checkbox-box.p-highlight {
    background: #9c4900 !important;
    background-color: #9c4900 !important;
    color: #9c4900 !important;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: #9c4900 !important;
  }

  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    background: #8f4300;
    color: #f6efe9;
    height: 20px;
  }

  .p-multiselect .p-multiselect-trigger {
    color: #8f4300;
  }

  .p-multiselect {
    border-radius: 10px;
    height: 32px;
    border: 1px solid #f6efe9;
  }

  .p-multiselect .p-multiselect-label {
    padding: 0.4rem 0.75rem;
  }

  .pi {
    font-size: 12px;
    font-weight: 600;
  }

  .p-multiselect .p-multiselect-label.p-placeholder {
    color: rgba(156, 73, 0, 0.5);
  }

  .p-multiselect:not(.p-disabled):hover {
    border: 1px solid #f6efe9;
  }

  .p-multiselect:not(.p-disabled).p-focus {
    box-shadow: none;
    border: 1px solid #f6efe9;
  }

  .p-inputtext {
    &:enabled {
      &:focus {
        box-shadow: none !important;
        border: 1px solid #f6efe9 !important;
      }
    }
  }

  .p-multiselect-panel {
    width: 130px !important;
  }

  .search-wrapper {
    border: 0.956px solid #f6efe9 !important;
    color: rgb(143, 67, 0) !important;
    font-size: 14px;
    min-height: 40px;
    border-radius: 10px !important;

    input {
      &::placeholder {
        color: rgba(143, 67, 0, 0.5) !important;
      }
    }
  }
}

.p-multiselect-filter-container .p-inputtext {
  padding: 0.2rem 0.75rem !important;
  border: 1px solid #f6efe9;
}

.p-multiselect-header {
  .p-checkbox {
    &:first-child {
      display: none !important;
    }
  }
}

.p-multiselect-panel .p-multiselect-header {
  padding: 0.75rem 1rem;
  background: #fff;
  border-bottom: none;
  border-radius: 10px 10px 0 0 !important;
}

.p-multiselect-close {
  display: none;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 5px 1.55rem !important;

  &:hover {
    background-color: #fff !important;
  }
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  color: rgba(156, 73, 0, 0.5) !important;
  background: #fff !important;
  font-size: 14px;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #8f4300 !important;
  background-color: #fff;

  &:focus {
    box-shadow: none !important;
  }

  &:focus-visible {
    box-shadow: none !important;
  }
}

.p-checkbox .p-checkbox-box.p-highlight {
  background-color: #8f4300 !important;
  background: #8f4300 !important;
  border-color: #8f4300 !important;
}

.p-multiselect-items-wrapper {
  border-radius: 0 0 10px 10px !important;
}

.p-multiselect-panel {
  border-radius: 10px !important;
}

.p-checkbox {
  display: flex;
  align-items: center;
  margin: 0 !important;
}

.p-checkbox .p-checkbox-box {
  border: 1px solid #8f4300 !important;
  width: 15px;
  height: 15px;
  border-radius: 3px;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 8px;
  font-weight: 600;
}

.p-inputtext:enabled:focus {
  box-shadow: none;
  border: 1px solid #f6efe9;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: none;
}

.p-inputtext:enabled:hover {
  border-color: #f6efe9;
}

.pi-search:before {
  color: rgba(156, 73, 0, 0.5);
}

.p-multiselect-panel .p-multiselect-items {
  padding: 0 0 0.75rem 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  text-align: center;
  color: red;
}

.p-inputtext {
  color: rgba(156, 73, 0, 0.8);
}

.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-multiselect-filter-icon {
  color: rgba(156, 73, 0, 0.8);
}

.sidebar_none {
  margin-left: 0 !important;
  padding: 20px !important;
  height: 100% !important;
}

.dashboard_share {
  width: 100% !important;
  height: calc(100vh - 40px) !important;
  padding: 20px !important;
  overflow: hidden !important;
}

.dashboard_responsive2 {
  height: 100% !important;
}

.dashboard_responsive2 .dashboard-main-map .map-wrapper .map-main {
  height: calc(100vh - 143px) !important;
}

.custom_height_adjust {
  overflow: hidden;
  // min-height: calc(100vh - 638px) !important;
  // max-height: max-content !important;
  height: auto !important;
}
